import { createFeatureFlag } from '@feature-flag-lib/createFeatureFlag'

export const FeatureFlags = {
    DashboardNewsWidget: createFeatureFlag('dashboard-news-widget', false),
    DeveloperTools: createFeatureFlag('developer-tools', false),
    FastTrackFilter: createFeatureFlag('fast-track-filter', false),
    FastTrackWidget: createFeatureFlag('fast-track-widget', false),
    FleetOverviewRatingDialog: createFeatureFlag(
        'fleet-management-show-rating-popup',
        { enablePopup: false }
    ),
    PathfinderRatingPopup: createFeatureFlag('pathfinder-rating-popup', {
        enablePopup: false,
    }),
    PathfinderNewUI: createFeatureFlag('pathfinder-use-new-ui', false),
    UserAnalytics: createFeatureFlag('user-analytics', false),
    WagonDetailsSensorDataMock: createFeatureFlag(
        'wagon-details-sensor-data-mock',
        false
    ),
}
